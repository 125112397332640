export const data = [
  {
    question:
      "В годы Великой Отечественной войны Ямало-Ненецкий округ имел статус национального и до 1944 г входил в состав ....",
    incorrectAnswers: [
      "Уральской области",
      "Тобольской губернии",
      "Тюменской области",
    ],
    correctAnswer: "Омской области",
  },
  {
    question:
      "Основными видами хозяйственной деятельности Ямало-Ненецкого национального округа были традиционные для Крайнего Севера ....",
    incorrectAnswers: [
      "Оленеводство и производство консервов",
      "Рыбная промышленность и полярное земледелие",
      "Оленеводство и пушные заготовки",
    ],
    correctAnswer: "Оленеводство, рыбное хозяйство и пушные заготовки",
  },
  {
    question:
      "В годы Великой Отечественной войны самым крупным населённым пунктом округа был ....",
    incorrectAnswers: ["Новый Уренгой", "Губкинский", "Ноябрьск"],
    correctAnswer: "Салехард",
  },
  {
    question:
      "В годы Великой Отечественной войны районный центр Тазовского района имел название ....",
    incorrectAnswers: ["Тазовский", "Тамбей", "Ярсале"],
    correctAnswer: "Хальмер-Сэде",
  },
  {
    question:
      "По данным архивного фонда «ФКУ Военный комиссариат Ямало-Ненецкого автономного округа» на Ямале было создано 7 взводов, состоящих из 366 человек. В Рабоче-крестьянскую Красную Армию по Ямало-Ненецкому национальному округу призвано ....",
    incorrectAnswers: ["10000 человек", "7952 человека", "12000 человек"],
    correctAnswer: "8982 человека",
  },
  {
    question:
      "В годы войны женщины ненки и ханты заменили ушедших на фронт мужчин, работали в женских рыболовецких бригадах. Первую такую бригаду организовала Вэнго Мария Александровна которая проживала в ....",
    incorrectAnswers: ["Ямальском районе", "Салехарде", "Надымском районе"],
    correctAnswer: "Тазовском районе",
  },
  {
    question:
      "За годы великой Отечественной войны в округе было добыто 810 000 центнеров рыбы, а сколько было произведено консервов с продукцией?",
    incorrectAnswers: ["30 000 000", "50 000 000", "10 000 000"],
    correctAnswer: "22 000 000",
  },
  {
    question:
      "В годы Великой Отечественной войны жители округа собирали деньги в фонд Обороны и на постройку военной техники, в частности на танки. 25 февраля 1943 года в областной газете «Омская правда» было опубликовано письмо Ады Занегиной «Мне шесть лет. Пишу по-печатному. Гитлер выгнал меня из города Сычевка Смоленской области. Я хочу домой. Маленькая я, а знаю, что надо разбить Гитлера и тогда поедем домой. Мама отдала деньги на танк. Я собрала на куклу 122 рубля и 25 копеек. А теперь отдаю их на танк. Дорогой дядя редактор! Напишите в своей газете всем детям, чтобы они тоже свои деньги отдали на танк» напишите название танка.",
    incorrectAnswers: [
      "Боевая подруга",
      "Советская патриотка",
      "Омский комсомолец",
    ],
    correctAnswer: "Малютка",
  },
  {
    question:
      "В годы Великой Отечественной войны в Ямало-Ненецком национальном округе самой технически развитой отраслью экономики была рыбная промышленность, а самым крупным предприятием был ....",
    incorrectAnswers: [
      "Тазовская консервная фабрика",
      "Полярный рыбозавод",
      "Северный рыбозавод",
    ],
    correctAnswer: "Салехардский рыбоконсервный комбинат",
  },
  {
    question:
      "В 1944 г. была создана Тюменская область, к Ямало-Ненецкому национальному округу был присоединён новый район, ранее входивший в состав Красноярского края. Его жители в войну занимались рыбодобычей, были прекрасными охотниками и сдавали пушнину государству, напишите название района.",
    incorrectAnswers: ["Тазовский", "Гыдоямский", "Шурышкарский"],
    correctAnswer: "Красноселькупский",
  },
  {
    question:
      "Как назывался легендарный ледокольный пароход. Впервые преодолел Северный морской путь за одну навигацию (1932). Следуя с Диксона, 25 августа 1942 года у острова Белуха погиб в неравном бою с немецким тяжёлым крейсером «Адмирал Шеер».",
    incorrectAnswers: ["Марина Раскова", "Дежнев", "Полярный"],
    correctAnswer: "Александр Сибиряков",
  },
  {
    question:
      "6 июня 1944 года в свои 19 лет младший сержант комсомолец повторил подвиг Александра Матросова. До войны наш герой жил в посёлке Шуга Надымского района Ямало-Ненецкого округа. Когда началась война ему было 16 лет и на фронт его не взяли, в 1942 г. поступил в Салехардское педагогическое училище. С декабря 1943 г. служил в Красной Армии. Как звали нашего героя?",
    incorrectAnswers: [
      "Бабичев Петр Алексеевич",
      "Архангельский Николай Васильевич",
      "Корольков Иван Васильевич",
    ],
    correctAnswer: "Зверев Анатолий Михайлович",
  },
  {
    question:
      "Герой Советского союза, лётчик. Рос и воспитывался на Тюменском севере. Его отец был директором школы в селе Мужи (с 1933 по 1937 г.) С 1937 г. поехал учится в Шадринск, поступил в аэроклуб. В августе 1940 был призван в 1940 г. в Красную армию, поступил в 1-ю Чкаловскую военно-авиационную школу пилотов. Будучи заместителем командира эскадрилий 57-го бомбардировочного авиационного полка 221-й бомбардировочного авиационной дивизии 16 –й воздушной армии Белорусского фронта, старший лейтенант. Во время войны экипаж самолёта под его командованием направил свой подбитый самолёт на располагающиеся на земле силы немецко-фашистких захватчиков.",
    incorrectAnswers: [
      "Панов Петр Яковлевич",
      "Логунов Александ Никитич",
      "Хатанзейский Андрей Гурьевич",
    ],
    correctAnswer: "Николай Васильевич Архангельский",
  },
  {
    question:
      "Герой советского Союза, лётчик за годы Великой Отечественной войны совершил 284 боевых вылета, в том числе 20 – на Берлин, Данциг, Кёнигсберг, Будапешт, Варшаву, Хельсинки В 1947-1953 годах он возглавлял сводный отряд легкомоторной авиации при 501 стройке. Самолеты авиаотряда выполняли аэросъемку, перевозили грузы и пассажиров. После закрытия стройки Герой работал начальником управления воздушного транспорта «Дальстроя», командиром Магаданского авиапредприятия, начальником международного аэропорта Шереметьево.",
    incorrectAnswers: [
      "Николай Васильевич Архангельский",
      "Анатолий Михайлович Зверев",
      "Иван Васильевич Корольков",
    ],
    correctAnswer: "Василий Александрович Борисов",
  },
  {
    question:
      "Первый профессиональный агроном Ямала, руководитель Ямальской опытной станции, в августе 1942 г. был призван в Красную Армию. В боях с фашистскими оккупантами показал себя преданным сыном Родины. Во время наступательного боя под сильным арт-миномётным огнём с воздуха, поле выбытия командира взвода, принял на себя командование взводом, будучи в бою дважды контуженным не покинул боевых порядков и поддерживал дисциплину и организованность. Награждён Орденом Красной Звезды и Медалью «За победу над Германией в Великой Отечественной войне 1941–1945 гг.», его именем названа одна из улиц Салехарда.",
    incorrectAnswers: [
      "Чубынин Дмитрий Мартинианович",
      "Городков Борис Николаевич",
      "Александр Александрович Дунин-Горкавич",
    ],
    correctAnswer: "Патрикеев Борис Владимирович",
  },
  {
    question:
      "Уроженец Ямальского района Герой Советского союза, за время своего участия в боевых действиях он совершил 152 боевых вылета на штурмовку скоплений боевой техники и живой силы противника, нанеся тому большие потери. В воздушных боях сбил 3 вражеских самолёта лично и ещё 2 – в составе группы.",
    incorrectAnswers: [
      "Николай Васильевич Архангельский",
      "Анатолий Михайлович Зверев",
      "Иван Васильевич Корольков",
    ],
    correctAnswer: "Александр Евстафьевич Звягин",
  },
  {
    question:
      "Доблестные охотники округа в годы войны с наступлением охотничьего сезона отправлялись на промысел. Пушнина, заготовляемая в Ямало-ненецком округе, шла на экспорт. Всего в годы войны было заготовлено пушнины на сумму более 21 миллиона рублей. Чей мех был основным в заготовках охотников?",
    incorrectAnswers: ["Соболь", "Каракуль", "Горностай"],
    correctAnswer: "Песец",
  },
  {
    question:
      "В 1943 г. Наркомат речного флота СССР принял решение перевести из Печоры в Обскую губу, 15 речных пароходов. Обеспечением безопасности кораблей занимался конвой из военных судов. В последствии, конвой, вышедший из Нарьян-Мара в Новый Порт (23 июля 1943 года), командующий Северным флотом адмирал А. Головко назовёт «самым необычным». Назовите суда какой флотилии сопровождали речные пароходы.",
    incorrectAnswers: [
      "Азовская военная флотилия (2-го формирования)",
      "Ильменская военная флотилия",
      "Северная Тихоокеанская военная флотилия",
    ],
    correctAnswer: "Беломорская военная флотилия",
  },
];
